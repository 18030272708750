import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
const Footer = () => {
    return (
        <>
            {/* <section>
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10373.058369636596!2d72.62864275163797!3d23.19099648979687!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395c2a39c9afec9d%3A0xc7a70a342c2395fb!2sInfocity%2C%20Gandhinagar%2C%20Gujarat%20382421!5e0!3m2!1sen!2sin!4v1661160523077!5m2!1sen!2sin" className="map_size" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </section> */}
            <section className="foot" id='footer'>
                <div className="container-fluid">
                    <div className="row text-white g-4 py-3 mx-auto align-items-lg-start align-items-center">
                        <div className="col-md-2 col-sm-4 col-lg-3 text-center order-sm-0 order-md-0">
                          <a className="navbar-brand logo " href="#">
                            <img src="/images/logo.jpg" alt="codeZ" className="img-fluid" />
                          </a>
                        </div>
                        <div className="col-md-3 col-sm-4 col-lg-3 text-white  order-sm-1 order-md-1">
                          <div className="align-items-star just">
                                      <h5 className="footer_title mb-3">Quick Link</h5>
                                      <div className="">
                                        <Link to={{pathname: "/", hash: "#home"}} className="footer_link"><i className="fas fa-angle-right me-2"></i>Home</Link>
                                      </div>
                                      <div className="">
                                        <Link to={{pathname: "/", hash: "#services"}} className="footer_link"><i className="fas fa-angle-right me-2"></i>Services</Link>
                                      </div>
                                      <div className="">
                                        <Link to={{pathname: "/", hash: "#tech"}} className="footer_link"><i className="fas fa-angle-right me-2"></i>Technology</Link>
                                      </div>
                                      <div className="">
                                        <Link to={{pathname: "/career", hash: "#career_home"}} className="footer_link"><i className="fas fa-angle-right me-2"></i>Careers</Link>
                                      </div>
                          </div>
                            
                        </div>
                        <div className="col-md-7 col-sm-12 col-lg-3 order-sm-4 order-md-2">
                            <div className="align-items-star just">
                              <h5 className="footer_title mb-3">Contact Us</h5>
                                  <div className="footer_link my-2">
                                          <i className="fas fa-map-marked-alt me-1 mt-1"></i>
                                        Infocity, Gandhinagar, Gujarat-382007
                                  </div>
                                  <div className="footer_link my-2">
                                      <a href="https://mail.google.com/mail/?view=cm&fs=1&to=codeztechnolabs@gmail.com" className="" target="_blank" rel="noopener noreferrer">
                                            <i className="fas fa-envelope me-1"></i>
                                          codeztechnolabs@gmail.com
                                      </a>
                                  </div>
                                  <div className="footer_link my-2">
                                      <a href="tel:+919427685363" className="">
                                          <i className="fas fa-phone-alt me-1"></i>
                                          +9194 2768 5363
                                      </a>
                                  </div>
                            </div>
                        </div>
                        <div className="col-md-12 col-sm-4 col-lg-3 order-sm-2 orde-md-3">
                            <div className="text-center">
                              <h5 className="footer_title mb-2">Follow Us</h5>
                              <div className="">
                                  <ul className="list-unstyled d-flex justify-content-center">
                                      <li>
                                          <a href="https://www.facebook.com/profile.php?id=100076200048432" className="text-white  me-4 fs-4" target="_blank">
                                              <i className="fab fa-facebook"></i>
                                          </a>
                                          <a href="https://www.linkedin.com/in/codez-technolabs-03a51a228" className="text-white text-decoration-none me-4 fs-4" target="_blank">
                                            <i className="fab fa-linkedin"></i>
                                          </a>
                                          <a href="https://join.skype.com/invite/yzf48M79LHVJ" className="text-white text-decoration-none  fs-4" target="_blank">
                                            <i className="fab fa-skype"></i>
                                          </a>
                                      </li>
                                  </ul>
                              </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div className="text-center text-white p-3" style={{backgroundColor: "rgba(0, 0, 0, 0.2)"}}>
                  © 2022 Copyright:
                  <a className="text-white" href="#">codeztechnolabs.com</a>
                </div>
            </section>
            <a href="#" id="#button" className="scroll-top on" role="button">
              <span>
              <i className="fas fa-arrow-up"></i>
              </span>
            </a>
        </>
    );
}

export default Footer;
