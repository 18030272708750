const ServicesL =[
    {
        id: 1,
        icon: "fa-mobile-alt",
        title: "Mobile App Development",
        strong: "Android, iOS App Services ",
        description: "includes  enterprise software, 3D animation, games, and virtual reality applications with hands-on experience in developing mobile apps for android, iOS.",
    },
    {
        id: 2,
        icon: "fa-laptop-code",
        title: "Software Development",
        description: "To build and design a custom software  for small businesses to Enterprise-Level Corporations that helps to grow the clients and customer business with their objective, goal or process.",
    },
    {
        id: 3,
        icon: "fa-columns",
        title: "Web Development",
        description: "The CodeZ developer team delivers outstanding and responsive web applications with the unique functionalities as per the customers/clients requisitions.",
    },
    {
        id: 4,
        icon: "fa-bezier-curve",
        title: "Web Designing",
        description: "Provide unique and compelling UX/UI design for Web, Software and Mobile Applications using up-to-date tools, platforms and technologies with 2D and 3D design effects.",
    },
    {
        id: 5,
        icon: "fa-users",
        title: "Dedicated Developers",
        description: "We provide dedicated developers and software engineers that implements, designs and maintains the applications with great flexibility.",
    },
    {
        id: 6,
        icon: "fa-file-code",
        title: "QA & Testing",
        description: "Our QA team works with the diversity to monitor every phase of the development process;",
    },
];
export default ServicesL;