import React from 'react'

const ContactUs = () => {
  return (
    <>
        <section id="contact" className="contact_us">
                <div className="container">
                    <div className="title text-center">
                        <h2 className="position-relative d-inline-block ms-4">Contact Us</h2>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-6 text-center text-lg-start mb-4 mb-sm-5 mb-lg-0 aos-init aos-animate" data-aos-duration="1800" data-aos="fade-right">
                            
                            <div className="contact-form">
                                <form action="https://formspree.io/f/xqkjpwnw" method="POST" className='contact-inputs'>
                                    <input className="form-control" type="text" 
                                        name="username" 
                                        placeholder='Username'
                                        autoComplete='off'
                                        required
                                    />
                                    <input className="form-control" type="email" 
                                        name="emai" 
                                        placeholder='Email Id'
                                        autoComplete='off'
                                        required
                                    />
                                    <input className="form-control" type="text" 
                                        name="phoneno" 
                                        placeholder='Phone Number'
                                        autoComplete='off'
                                        pattern="[7-9]{1}[0-9]{9}"
                                        title="Please enter valid phone number"
                                        required
                                    
                                    />
                                    <textarea className="form-control"
                                        name='message' 
                                        placeholder='Message'
                                        cols="30" 
                                        rows="6"
                                        autoComplete='off'
                                        required>
                                        
                                    </textarea>

                                    <input type="submit" value="Submit" />
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-6 text-center aos-init aos-animate" data-aos-duration="1800" data-aos="fade-left">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10373.058369636596!2d72.62864275163797!3d23.19099648979687!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395c2a39c9afec9d%3A0xc7a70a342c2395fb!2sInfocity%2C%20Gandhinagar%2C%20Gujarat%20382421!5e0!3m2!1sen!2sin!4v1661160523077!5m2!1sen!2sin" className="map_size" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
        </section> 
    </>
  )
}
export default ContactUs