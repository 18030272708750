import React from 'react';
import data from '../data/data.json'
const Work = () => {
    return (
        <>
            <section id="work" className="work_bg">
                <div className="container">
                  <div className="title text-center">
                    <h2 className="position-relative d-inline-block">Our Work Process
                    </h2>
                  </div>
                  <div className="roadmap">
                      {data.work.map((workElm,id) => {
                        return(
                          <>
                          <div className="row gy-3 mb-3 mb-md-5 align-items-center">
                            <div key={id} className={`col-md-6 order-1 ${workElm.order}`}>
                              <div className={`step-pr ${workElm.alter} shadow mx-auto ${workElm.me} ${workElm.ms} ${workElm.order} aos-init aos-animate`} data-aos-duration="1800" data-aos={workElm.aos}>
                                  <i className={`fas ${workElm.fas} icon`}></i>
                              </div>
                            </div>
                            <div className={`col-md-6 order-2 ${workElm.order2} ${workElm.text}`}>
                              <div className={`text-center ${workElm.text} ${workElm.m} d-md-inline-block aos-init aos-animate`} data-aos-duration="1800" data-aos={workElm.aos1}>
                                <div className="work_title">
                                  <h3>{workElm.title}</h3>
                                </div>
                              </div>
                            </div>
                          </div>
                          </>
                        )
                      })}  
                  </div>  
                </div>
            </section>  
        </>
    );
}

export default Work;
