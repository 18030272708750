import React from 'react'

const Career = () => {
  return (
    <>
      <section id="career_home" className="intro-Wrap bg2 pd">
          <div className="container">
            <div className="row justify-content-center">
                      <div className="col-lg-8 col-md-10 col-12 text-center h-100">
                        <div className="banner_contant  aos-init" data-aos="fade-up" data-aos-duration="1800">
                            <h2 className='heading'>WORK AT CODEZ TECHNOLABS</h2>
                            <p className="sub_text">Haven’t found the position you’re interested in? Send us your CV and we’ll get in touch as soon as a perfect opportunity becomes available!
                            </p>
                            <a href="https://mail.google.com/mail/?view=cm&fs=1&to=codeztechnolabs@gmail.com" target="_blank" className="btn btn_main">Contact Us</a>
                        </div>
                      </div>
                      {/* <div className="col-md-6 text-end">
                      <div data-aos="fade-left" data-aos-duration="1800" className="career_img aos-init">
                          <img src="./images/good-team-animate.svg" className="img-fluid" alt=""/>
                      </div>
                      </div> */}
            </div>
            <div className="overlay">
            </div>
          </div>
      </section> 
      <section id="career">
              <div className="container">
                  <div className="title text-center">
                    <h2 className="position-relative d-inline-block">Open positions</h2>
                  </div>
                  <div className="row g-0">
                    <div className="bt-4 d-flex justify-content-center flex-wrap">
                        <div className="career_item tab_section aos-init aos-animate" data-aos-duration="1800" data-aos="fade-right">
                                <h3 className="career_title text-center">React Js</h3>
                                <p className="career_sub_title">ReactJS Developer (Full Time)
                                </p>
                                <ul className="clist">
                                <li><strong>Position:1</strong></li>
                                <li>Experience: 2 to 3 years</li>
                                <li>Joining: Immediate (WFO)</li>
                                <li><strong>Essential Qualifications:</strong> B.E./BTech/MTech/BCA/MCA.</li>
                                <li>Working: 5 days/week</li>
                                <li>Salary CTC Package: 2 to 6 LPA</li>
                                <li><strong>Description: </strong> <br />
                                  Must have proficiency in HTML, CSS and JavaScript. <br />
                                  In addition, knowledge in NodeJS/VueJS/Angular will be a plus point. <br />
                                  Enthusiastic and great team player.
                                </li>
                                </ul>
                                <div className="blob"></div>
                        </div> 
                        <div className="conrainer">
                          <div className="row d-flex justify-content-center">
                            <div className="col-md-12 col-11 career_contant">
                              <p>Interested and Eligible candidates can share the resume @<a href="https://mail.google.com/mail/?view=cm&fs=1&to=codeztechnolabs@gmail.com" target="_blank" rel="noopener noreferrer"> codeztechnolabs@gmail.com</a>   
                              </p>
                              <p>For further detail and information, contact on <a href="tel:+919427685363">+9194 2768 5363</a></p>
                            </div>
                          </div>
                        </div>
                    </div>
                  </div>
              </div>
      </section>
    </>
  )
}

export default Career
