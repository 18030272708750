import React from 'react';

const AboutUs = () => {
    return (
        <>
           <section id="about" className="about_us">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 order-lg-1 text-center text-lg-start mb-5 aos-init aos-animate" data-aos-duration="1800" data-aos="fade-left">
                            <div className="title">
                                <h2 className="position-relative d-inline-block ms-4">About Us</h2>
                            </div>
                            <p className="mb-2">CodeZ Technolabs is a software development company that develops digital products, platforms, and experiences for their clients that engage users and scale on demand.</p> 
                            <p className="mb-4">From strategy to delivery, our winning combination of comprehensive IT solutions and experienced professionals unite to accelerate change from the workplace to the data centre and into the cloud. </p> 
                            <p className="mb-2"><strong>Purpose:</strong> We empower our global partners to achieve great outcomes with technology.</p>
                            <p className="mb-2"><strong>Mission:</strong> To connect the global IT ecosystem and unlock its potential for all.</p> 
                            <p className="mb-2"><strong>Vision:</strong> We deliver compelling technology solutions to a dynamic global marketplace.</p>  
                        </div>
                        <div className="col-lg-6 order-lg-0 text-center">
                            <div className="about_img aos-init aos-animate" data-aos-duration="1800" data-aos="fade-right">
                                <img src="./images/pexels-yan-krukov-7698885.jpg" alt="" className="img-fluid"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section> 
        </>
    );
}

export default AboutUs;
