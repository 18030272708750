import React from 'react';

const Banner = () => {
    return (
        <>
           <section id="home" className="intro-Wrap bg1">
                <div className="container">
                <div className="row d-flex align-items-center">
                    <div className="col-md-6 h-100">
                    <div className="banner_contant aos-init" data-aos="fade-right" data-aos-duration="1800">
                        <h6 className="h_sub_text">IT Solution</h6>
                        <h1>CODEZ TECHNOLABS</h1>
                        <p className="sub_text">CodeZ Technolabs provides Web and Mobile App Development  that delight users and keep your business growing.
                        </p>
                        <a href="#footer" className="btn btn_main">Explore Now</a>
                    </div>
                    </div>
                    <div className="col-md-6 justify-content-end">
                    <div data-aos="fade-left" data-aos-duration="1800" className="banner_img aos-init">
                        <img src="./images/web-devices-animate.svg" className="img-fluid" alt=""/>
                    </div>
                    </div>
                </div>
                <div className="overlay">
                </div>
                </div>
            </section> 
        </>
    );
}

export default Banner;
