import React, { useState } from 'react'
import { HashLink as Link } from 'react-router-hash-link';
import Records from "../data/data.json"
export default function NavBar() {
    const [data, setData] = useState(Records);
  return (
    <>
         <header>
            <nav className="navbar fixed-top navbar-expand-lg main-header">
                <div className="container d-flex align-items-center justify-content-between">
                <a className="navbar-brand logo " href="#">
                    <img src="./images/logo.jpg" alt="codeZ" className="img-fluid" />
                </a>
                <button className="navbar-toggler p-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navbar">
                    <i className="fas fa-stream navbar-toggler-icon whc"></i>
                </button>
                <div className="collapse navbar-collapse align-items-center" id="navbarNav">
                    <ul className="navbar-nav ms-auto mb-lg-0">
                    <li className="nav-item">
                        <Link className="nav-link active"   aria-current="page" to={{pathname: "/", hash: "#home"}}>Home</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" data={data} to={{pathname: "/", hash: "#services"}}>Services</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to={{pathname: "/", hash: "#tech"}}>Technology</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to={{pathname: "/career", hash: "#career_home"}}>Careers</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to={{pathname: "/", hash: "#about"}}>About Us</Link>
                    </li>
                    </ul>
                </div>
                </div>
            </nav>
        </header>
        
    </>
  )
}
