import React from 'react'
// import AnimatedCursor from "react-animated-cursor"
import NavBar from './componants/NavBar'
import Footer from './componants/Footer'
import Home from './pages/Home'
import Career from './pages/Career'
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
const App = () => {
  
 
  return (
    <>
    <Router>
          <NavBar/>
           <Routes>
              <Route exact path="/" element={<Home/>} />
              <Route exact path="/career" element={<Career/>} />
           </Routes>
           <Footer/>
    </Router>
    {/* <AnimatedCursor innerSize={8}
      outerSize={8}
      color='5, 44, 113' outerAlpha={0.4}
      innerScale={8}
      outerScale={5} trailingSpeed={8} outerStyle={{
        padding: "10px",
        backgroundColor: "transparent",
        border: '1px solid rgb(5, 44, 113)'
      }}  clickables={[
        'a',
        'input[type="text"]',
        'input[type="email"]',
        'input[type="number"]',
        'input[type="submit"]',
        'input[type="image"]',
        'label[for]',
        'select',
        'textarea',
        'button',
        '.link'
      ]}/> */}
    </>
  )
}

export default App

