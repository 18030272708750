import React from 'react';
import data from '../data/data.json'
const Technology = () => {
    
    return (
        <> 
          <section id="tech" className="technology sm_100">
            <div className="container">
              <div className="title text-center">
                  <h2 className="position-relative d-inline-block">Technology Stack</h2>
              </div>
              <div className="row g-0">
                <ul  className="nav nav-tabs justify-content-center filter-button border-0" role="tablist">
                    <li className="nav-item ">
                      <a className="nav-link btn m-md-2 text-dark active" href="#frontend" data-bs-toggle="tab" type="button" role="tab">Front End</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link btn m-md-2 text-dark" type="button" href="#backend" data-bs-toggle="tab" role="tab">Back End</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link btn m-md-2 text-dark" type="button" href="#database" data-bs-toggle="tab" role="tab">Database</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link btn m-md-2 text-dark" type="button" href="#mobile" data-bs-toggle="tab" role="tab">Mobile</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link btn m-md-2 text-dark" type="button" href="#cloud" data-bs-toggle="tab" role="tab">Cloud</a>
                    </li>
                </ul>

                <div className="tab-content border-0 mt-3">
                  <div className="tab-pane active" role="tabpanel" id="frontend">
                    <div className="bt-4 d-flex justify-content-center flex-wrap">
                      {data.techData.map(( techelm,id) =>{
                        return(
                                <>
                                  <div key={id} className="stack_item tab_section aos-init aos-animate" data-aos-duration="1800" data-aos={techelm.classe}>
                                    <img src={techelm.img} className="ct-image tab-image img-fluid" alt=""/>
                                    <h6 className="stack_title">{techelm.title}</h6>
                                  </div>
                                </>
                              )
                      })}       
                    </div>
                  </div>

                  <div className="tab-pane" role="tabpanel" id="backend">
                    <div className="bt-4 d-flex justify-content-center flex-wrap">
                      {data.backend.map(( backelm,id) =>{
                        return(
                                <>
                                  <div key={id} className="stack_item tab_section aos-init aos-animate" data-aos-duration="1800" data-aos={backelm.classe}>
                                    <img src={backelm.img} className="ct-image tab-image img-fluid" alt=""/>
                                    <h6 className="stack_title">{backelm.title}</h6>
                                  </div>
                                </>
                        )
                      })}
                    </div>
                  </div>

                  <div className="tab-pane" role="tabpanel" id="database">
                    <div className="bt-4 d-flex justify-content-center flex-wrap">
                      {data.database.map(( dataelm,id) =>{
                        return(
                                <>
                                  <div key={id} className="stack_item tab_section aos-init aos-animate" data-aos-duration="1800" data-aos={dataelm.classe}>
                                    <img src={dataelm.img} className="ct-image tab-image img-fluid" alt=""/>
                                    <h6 className="stack_title">{dataelm.title}</h6>
                                  </div>
                                </>
                              )
                      })}
                    </div>
                  </div>

                  <div className="tab-pane" role="tabpanel" id="mobile">
                    <div className="bt-4 d-flex justify-content-center flex-wrap">
                      {data.mobile.map(( dataelm,id) =>{
                        return(
                                <>
                                  <div key={id} className="stack_item tab_section aos-init aos-animate" data-aos-duration="1800" data-aos={dataelm.classe}>
                                    <img src={dataelm.img} className="ct-image tab-image img-fluid" alt=""/>
                                    <h6 className="stack_title">{dataelm.title}</h6>
                                  </div>
                                </>
                              )
                      })}
                    </div>
                  </div>

                  <div className="tab-pane" role="tabpanel" id="cloud">
                    <div className="bt-4 d-flex justify-content-center flex-wrap">
                      {data.could.map(( dataelm,id) =>{
                        return(
                                <>
                                  <div key={id} className="stack_item tab_section aos-init aos-animate" data-aos-duration="1800" data-aos={dataelm.classe}>
                                    <img src={dataelm.img} className="ct-image tab-image img-fluid" alt=""/>
                                    <h6 className="stack_title">{dataelm.title}</h6>
                                  </div>
                                </>
                              )
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
    );
}

export default Technology;
