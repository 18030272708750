import React, { useState } from 'react'
import Banner from '../componants/Banner'
import Technology from '../componants/Technology'
import Services from '../componants/Services'
import ServicesL from '../list/servicesList'
import Work from '../componants/Work'
import AboutUs from '../componants/AboutUs'
import ContactUs from '../componants/ContactUs'
const Home = () => {
    const [serviceData, setservice] = useState(ServicesL);
  return (
    <>
                <Banner/>
                <Services serviceData={ serviceData } />
                <Work/>
                <Technology /> 
                <AboutUs/>
                <ContactUs/>
    </>
  )
}

export default Home
