import React from 'react';
import Record from "../data/data.json"
const Services = ( ) => {
    return (
        <>
           <section id="services" className="">
              <div className="container">
                <div className="title text-center">
                    <h2 className="position-relative d-inline-block">Services</h2>
                </div>
                <div className="services_list row gx-0 gy-3">
                    {Record.services && Record.services.map(( data ,id)  => {
                        return(
                            <>
                                <div key={id} className="col-md-6 h-100 col-lg-6 col-xl-4 p-2 aos-init aos-animate"  data-aos-duration="1800" data-aos={data.animation}>
                                    <div className="item_inner d-flex flex-column align-items-center">
                                        <div className="item_overlay"></div>
                                        <div className="item_holder ">
                                                <div className="item_icon"><i className={`fas ${data.icon}`}></i></div>
                                                <h3 className="item_title">{data.title}</h3>
                                                <div className="item_readmore"><a href="">Read More...</a></div>
                                                <p className="item_description"><strong>{data.strong}</strong>{data.description}
                                                </p><br/>
                                                <ul className="list">
                                                    {
                                                        Record.ullist.map((item, id) => {
                                                        if (item.fid == data.id)
                                                            return (
                                                                <div key={id}>
                                                                    <li><strong>{item.strong}</strong>{item.description}</li>
                                                                </div>
                                                            );
                                                    })}      
                                                </ul>   
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    })}
                </div>
              </div>
           </section>
        </>
    );
}

export default Services;
